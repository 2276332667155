$small-width: 40em;
$medium-width: 64em;
$large-width: 90em;

@mixin phone {
    @media (max-width: #{$small-width}) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: #{$medium-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$small-width + 0.063em }) and (max-width: #{$medium-width}) {
    @content;
  }
}

@mixin tablet-up {
    @media (min-width: #{$small-width + 0.063em }) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$medium-width + 0.063em}) {
    @content;
  }
}

@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}
