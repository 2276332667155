body {
	// background-color: #FFF;
}
.app-wrapper {
	position: relative;
	display: block;
}

button:focus, input:focus, textarea:focus, select:focus, a:focus {
  outline: none;
}

ul {
	list-style-type: none;
	padding-left: 0;
	margin-left: 0;
}

aside, .menu-wrapper {
	z-index: 2;
}

button {
	cursor: pointer;
}

.aside-wrapper {
	background-color: $red;
	position: fixed;
	top: 0;
	width: 420px;
	left: -415px;
	height: 100vh;
	background-color: #ececec;
	border-right: solid 10px $red;
	i, svg { 
		transition: all 0.2s linear;
	}
	&.is-open {
		.floating-menu button {
			i, svg {
				transform: scale(-1);
			}
		}
	}
}
.aside-inner {
	position: relative;
	.floating-menu {
		position: absolute;
		top: 90px;
		right: 0;
		transform: translateX(100%);
		button {
			cursor: pointer;
			border: none;
			color: #fff;
			background-color: $red;
			border-radius: 0 50px 50px 0;
			padding: 1rem 0.15rem;
		}
	}
}

.aside-list {
	a {
		line-height: 2;
	}
}

.menu-wrapper {
	padding: 210px 0 0;
	position: fixed;
	top: 0;
	right: -420px;
	width: 420px;
	height: 100vh;
	background-color: #FFF;
	.menu-title {
		padding-left: 2rem;
		font-size: 2.4rem;
		font-weight: 700;
	}
	.close-menu {
		padding: 2rem;
	}
	.menu-body {
		padding: 1rem 2rem 3rem;
		a {
			font-size: 1.4rem;
			line-height: 2.4;
			border-left: solid 4px #fff;
			padding-left: 1rem;
			transition: all 0.2s linear;
			font-weight: 700;
			&.is-active, &:hover {
				border-color: $red;
				color: $red;
			}
		}
	}
	a, button {
		color: #000;
		&:hover {
			color: $red;
		}
	}
	.menu-footer {
		margin-top: 3rem;
		background-color: #000;
		text-align: center;
		a {
			color: #fff;
			line-height: 3;
			display: block;
			font-size: 1.2rem;
			svg {
				fill: #fff;
				vertical-align: middle;
				margin-right: 0.5rem;
			}
		}
	}
}

.main-page {

}

header {
	background-color: #ececec;
	&.index-header {
		background-color: transparent;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;
		.logo a, button {
			padding: 2rem 2.4rem;
			background-color: transparent;
		}
		button {
			color: #fff;
		}
	}
	.logo a, button {
		padding: 0.5rem 2rem;
	}
	.logo a {
		display: block;
	}
	button {
		background-color: transparent;
		color: #333;
	}
}





.section-hero-slideshow {
	position: relative;
	background-position: center center;
  background-size: cover;
}
.hero-slideshow {
	.slideshow-item {
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		width: 100%;
		height: 80vh;
		text-align: right;
		img {
			margin-left: auto;
			margin-right: 0;
		}
		.button {
			background-color: #FFF;
			color: $navy;
			padding-top: 0.5rem;
			padding-bottom: 0.25rem;
			padding-left: 1.6rem;
			padding-right: 1rem;
			font-weight: 700;
			position: relative;
			left: 30%;
			i, svg {
				vertical-align: middle;
				width: 16px;
				font-weight: 700;
				position: relative;
				top: -1px;
			}
		}
	}
}

.hero-menu-wrapper {
	position: absolute;
	left: 20%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 240px;
	height: 240px;
	.hero-menu {
		.menu-item {
			padding: 0.1rem;
			position: relative;
			img {
				width: 100%;
			}
		}
		h4 {
			font-size: 0.8rem;
			text-align: center;
			color: #fff;
			position: absolute;
			top: 1rem;
			left: 0;
			width: 100%;
		}
	}
}

footer {
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1d060e+0,450000+29 */
	background: rgb(29,6,14); /* Old browsers */
	background: -moz-linear-gradient(top,  rgba(29,6,14,1) 0%, rgba(69,0,0,1) 29%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(29,6,14,1) 0%,rgba(69,0,0,1) 29%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(29,6,14,1) 0%,rgba(69,0,0,1) 29%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d060e', endColorstr='#450000',GradientType=0 ); /* IE6-9 */

	color: #fff;
	padding-bottom: 3rem;
	padding-top: 2rem;
	h3 {
		font-size: 1rem;
		font-weight: 700;
	}
	a {
		color: #fff;
	}
	.footer-block {
		padding: 2rem;
	}
	.line-divider {
		width: 2px;
		height: 90px;
		border-left: solid 2px #fff;
		padding: 0 !important;
	}
}

.flinks-1 {
	text-align: center;
	ul {
		text-align: left;
		// width: auto;
		// display: inline-block;
		// padding: 1rem;
	}
}




aside {
	.tabs-title {
		text-align: center;
		width: 50%;
		border-bottom: solid 4px #ccc;
		a {
			font-weight: 700;
			font-size: 1.2rem;
			color: #ccc;
		}
		&.is-active {
			a {
				color: $red;
			}
			border-color: $red;
		}
	}
	.tabs-content {
		background-color: #eee;
	}
}

aside .section-category {
	.zaccordion-item {
		position: relative;
		&.is-active {
			a {
				color: #0a0a0a;
			}
		}
	}
	.zaccordion-title {
		& + .zaccordion-toggle {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
	ul {
    list-style: none;
    padding: 0;
    .inner {
      padding-left: 1em;
      overflow: hidden;
      display: none;
    
      &.show {
        /*display: block;*/
      }
	  }

	  li {
      margin: .5em 0;
      .zaccordion-toggle {
        // width: 100%;
        display: block;
        // background: rgba(0,0,0,0.78);
        // color: #fefefe;
        // padding: .75em;
        // border-radius: 0.15em;
        transition: background .3s ease;
      
        &:hover {
            // background: rgba(0, 0, 0, 0.9);
        }
      }
    }
	}
	a {
		color: #999;
		button {
			color: #999;
		}
	}
	.show {
		a {
			color: #0a0a0a;
			button {
				color: #0a0a0a;
			}
		}
	}
}

.page {
	// padding-top: 120px;	
	background-color: #ececec;
	.page-inner {
		background-color: #FFF;
		padding: 1rem 2rem;
	}
}


.portfolio-item {
	position: relative;
	img {
		width: 100%;
		text-align: center;
	}
	h3 {
		position: absolute;
		bottom: 2rem;
		left: 50%;
		transform: translate(-50%, -50%);
		text-shadow: 0 0 10px #000;
		color: #fff;
		text-transform: capitalize;
	}
}

.page-header {
	padding-top: 2rem;
	> div {
		border-bottom: solid 4px #eee;
		padding-right: 1rem;
	}
	.page-title {
		font-size: 1.8rem;
		font-weight: 700;
		margin-bottom: 0;
		line-height: 2;
		padding-right: 1rem;
	}
	.page-title-wrapper {
		border-color: $red;
		padding-bottom: 0.25rem;
		&.secondary-title-wrapper {
			border-color: #eee;
			padding-left: 2rem;
			.page-title {
				font-weight: 400;
			}
			a {
				color: #333;
			}
		}
	}
	select {
		min-width: 240px;
		margin-bottom: 1rem;
		border: none;
		border-bottom: solid 2px #eee;
		font-size: 1rem;
		option {
		}
	}
	.search-wrapper {
		position: relative;
		padding-right: 2rem;
		min-width: 300px;
		.icon {
			position: absolute;
			top: 0.5rem;
			right: 2rem;
			color: $primary-color;
			i, svg {
				font-size: 0.5rem;
				font-weight: 700;
			}
			&.feather {
				width: 16px;
				height: 16px;
				stroke-width: 3;
			}
		}
	}
	.search {
		min-width: 100%;
		border: none;
		box-shadow: none;
		border-bottom: solid 2px #eee;
	}

}

.page-portfolio-list {
	.page-header {
		margin-left: 1rem;
		margin-right: 1rem;
	}
}

.page-portfolio-detail {
	.slideshow-nav {
		.slick-track {
			margin-left: 0;
		}
	}
	.slick-slide {
		text-align: center;
		img {
			margin: auto;
		}
	}
	.page-title {
		text-transform: capitalize;
	}
	.page-content {
		padding-top: 2rem;
	}
}

.button-inverse {
	background-color: transparent;
	color: $primary-color;
}

.list-category {
	margin-top: 4rem;
	.list-category-item {
		padding: 1rem;
	}
	.category-item {
		// padding: 0.5rem;
		position: relative;
		.title {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-shadow: 0 0 10px #000;
			color: #fff;
			font-size: 1.4rem;
			font-weight: 700;
		}
		.image {
			// background-position: center center;
   //    background-size: cover;
   //    background-repeat: no-repeat;
   		// filter: grayscale(100%);
		}
		.red-filter {
			transition: opacity 0.15s linear;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: transparentize($red, 0.3);
		}
		&:hover {
			.red-filter {
				opacity: 1;
			}
		}
	}
	.is-active {
		.category-item {
			.red-filter {
				opacity: 1;
			}
		}
	}
	.ancestor-item {
		.category-item {
			.red-filter {
				opacity: 1;
			}
		}
		&.ancestor-1 {
			.red-filter {
				background-color: transparentize(darken($red, 20%), 0.3);
			}
		}
		&.ancestor-2 {
			.red-filter {
				background-color: transparentize(darken($red, 30%), 0.3);
			}
		}
		&.ancestor-3 {
			.red-filter {
				background-color: transparentize(darken($red, 40%), 0.3);
			}
		}
	}
}

.list-items {
	&.list-in-grids {
		display: flex;
		li {
			display: flex;
		}
	}
	&.list-in-list {
		display: block;
		li {
			display: block;
		}
	}
}

.page-about {
	padding: 3rem 2rem;
	background-color: #FFF;
	.page-content {
		padding-top: 0;
	}
	.page-header {
		padding-top: 0;
	}
	.page-header > div {
		border-bottom: none;
		padding-right: 0;
	}
	.tabs-title {
		border-bottom: solid 6px #eee;
		display: block;
		width: 100%;
		transition: all 0.2s linear;
		a {
			color: #333;
			padding: 0 0.5rem 1rem;
			background-color: transparent;
			display: block;
			h3 {
				line-height: 1;
				font-size: 1.8rem;
				padding-bottom: 0;
				margin-bottom: 0;
				font-weight: 400;
				transition: all 0.2s linear;
			}
		}
		&.is-active {
			border-color: $red;
			h3 {
				font-weight: 700;
			}
		}
	}
	img {
		width: 100%;
	}
	.page-content {
		padding: 0 2rem 2rem;
	}
	.content-wrapper {
		padding-bottom: 2rem;
		padding-top: 2rem;
		> div {
			background-color: #efefef;
			margin-bottom: 0.5rem;
			.vm-description {
				padding-left: 2rem;
			}
		} 
		> .description {
			background-color: #FFF;
			padding-bottom: 2rem;
		}
	}
}

.page-product-details {
	background-color: #FFF;
	.page-header {
		padding-top: 0;
	}
	.page-title {
		text-transform: uppercase;
		font-weight: 700;
	}
	.page-inner {
		// max-width: 1024px;
		// margin: auto;
	}
}

.product-description {
	.zaccordion-item {
		position: relative;
		&.is-active {
			a {
				color: #0a0a0a;
			}
		}
	}
	.zaccordion-title {
		& + .zaccordion-toggle {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
	ul {
    .inner {
      padding-left: 1rem;
      padding-right: 1rem;
      overflow: hidden;
      display: none;
      background-color: #ececec;
      &.show {
        /*display: block;*/
      }
	  }

	  li {
      margin: .5em 0;
      .zaccordion-toggle {
        // width: 100%;
        // display: block;
        // background: rgba(0,0,0,0.78);
        // color: #fefefe;
        // padding: .75em;
        // border-radius: 0.15em;
        transition: background .3s ease;
      
        &:hover {
            // background: rgba(0, 0, 0, 0.9);
        }
      }
    }
	}
	.zaccordion-title {
		cursor: pointer;
		background-color: $navy;
		color: #fff;
		padding: 0.5rem 1.6rem;
		text-transform: capitalize;
		line-height: 2;
		font-weight: 700;
	}	
}

.page-contact {
	background-color: #FFF;
	.page-header {
		padding-top: 1rem;
		margin-bottom: 2.4rem;
	}
	.form-item {
		input, textarea {
			background-color: #ececec;
			border: none;
			box-shadow: none;
		}
	}
	.form-item {
		button {
			margin-top: 1rem;
			padding-left: 2rem;
			padding-top: 0.5rem;
			padding-bottom: 0.25rem;
			background-color: $navy;
			i, svg {
				vertical-align: middle;
				margin-left: 0.25rem;
			}
		}
	}
	.contact-grid {
		margin-top: 1.5rem;
		i, svg {
			vertical-align: middle;
		}
	}
}

