$facebook-color: #3b5998;
$twitter-color: #55acee;
$google-color: #d50f25;

$grey: #f3f3f3;
$smoke: #ebebeb;
$smooth: #999;
$coffee: #000;
$white: #fff;
$black: #333;

$light-black: #1a1a1a;
$dark-black: #0d0d0d;
$smooth-black: #4d4d4d;
$black-2: #666666;

$dblue: hsla(207, 100%, 24%, 1);
$lime: hsla(82, 80%, 62%, 1);
$purple: #800080;
$yellow: hsla(48, 100%, 62%, 1);
$night-light: hsla(45, 100%, 62%, 1);

$blue1: #5bcaee;
$blue2: #00a5e2;
$blue3: #1b75ab;
$blue4: #008cff;

$red: #a40000;


$p1: #800080;
$b1: #008cff;
$b2: #2b3880;
$b3: #1b75a9;
$k1: #231f20;

$blue: $b1;
$navy: #162051;
$teal: $b3;

$sblue1: #2C3880;
$sblue2: #394DAA;
$sblue3: #4862D5;
$sblue4: #5777FF;

// report pdf color: 
$purple2: #614d9e;
$yellow2: #fcda00;
$blue5: #30c4f2;
$teal2: #6CD7BB;


.heading-1 {
	font-family: "Raleway", Helvetica, sans-serif;
	font-size: 20px;
	color: $b1;
	text-transform: uppercase;
}

// @font-face {
//   font-family: 'Gilroy-Light';
//   src: url('../fonts/Gilroy-Light.otf');
// }
// @font-face {
//   font-family: 'Gilroy-ExtraBold';
//   src: url('../fonts/Gilroy-ExtraBold.otf');
// }
// @font-face {
//   font-family: 'Gotham Rounded Bold';
//   src: url('../fonts/GothamRnd-Bold.otf');
// }
// @font-face {
//   font-family: 'Gotham Rounded Medium';
//   src: url('../fonts/GothamRnd-Medium.otf');
// }

$sans1: "Raleway", Helvetica, sans-serif;
$sans2: "Raleway", Helvetica, sans-serif;

// $gilit: "Gilroy-Light", Arial, sans-serif;
// $gileb: "Gilroy-ExtraBold", Arial, sans-serif;
// $gotrb: "Gotham Rounded Bold", Arial, sans-serif;
// $gotrm: "Gotham Rounded Medium", Arial, sans-serif;
$gilit: "Raleway", Helvetica, sans-serif;
$gileb: "Raleway", Helvetica, sans-serif;
$gotrb: "Raleway", Helvetica, sans-serif;
$gotrm: "Raleway", Helvetica, sans-serif;

$cstd: "Circular STD", Arial, sans-serif;